import { Admin, Authenticated, CustomRoutes, Resource, useAuthState } from "react-admin";
import authProvider from "./authProvider";
import { dataProvider } from "./dataProvider/dataProvider";
import { Card, CardContent, Grid } from "@mui/material";
import { Route, useNavigate } from "react-router-dom";
import users from './resources/users';
import userGroups from './resources/userGroups';
import workouts from './resources/workouts';
import sportProfiles from "./resources/sportProfiles";
import subjectiveData from "./resources/subjectiveData";

import { AppLayout } from "./components/organisms/layout";
import Analytics from "./components/views/analytics";
import { Privacy } from "./components/views/privacy";
import { Landing } from "./components/views/landing";


const App = () => {
  return (
    <Admin authProvider={authProvider} dataProvider={dataProvider} dashboard={Dashboard} layout={AppLayout}>
      <Resource name="users" {...users} />
      <Resource name="userGroups" {...userGroups} />
      <Resource name="workouts" {...workouts} />
      <Resource name="sportProfiles" {...sportProfiles} />
      <Resource name="subjectiveDatas" {...subjectiveData} />
      <CustomRoutes>
        <Route path="/analytics" element={<Authenticated><Analytics /></Authenticated>} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/app" element={<Landing />} />
      </CustomRoutes>
    </Admin>
  )
};

export default App;

export const Dashboard = () => {
const { isLoading, authenticated } = useAuthState();
const navigate = useNavigate();

if (!authenticated) {
  navigate('/app')
}
 return (
  <Grid container spacing={2} mt={1}>
    <Grid item xs={12}>
      <Card>
        <CardContent>
          RPE Trainer: Admin
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);}