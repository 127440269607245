import { Grid, GridProps } from "@mui/material"
import { useMemo } from "react";

interface ItemProps extends GridProps {
  empty?: boolean;
}

export const Item = (itemProps: ItemProps) => {

  const { empty, ...rest } = itemProps;

  const props = useMemo(() => {
    return {
      ...rest,
      ...(empty ? { height: "100%" } : {})
    }
  }, [empty, rest])

  return (
    <Grid {...props} item xs={12} >
      {props.children}
    </Grid>
  )
}
