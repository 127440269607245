import { List as ListBase, ListProps as ListPropsBase } from "react-admin";

interface ListProps extends ListPropsBase {
}

export const List = (listProps: ListProps) => {

  const { children, ...props } = listProps;

  return (
    <ListBase
      {...props}
    >
      {children}
    </ListBase>
  )
}
