import { BooleanField, Datagrid, DateField, List, NumberField, ReferenceField } from "react-admin"
import { useCallback } from "react";
import { Filter } from "./filterForm";
import { ListActions } from "../../components/organisms/listActions";

export const SubjectiveDataList = () => {

  const Actions = useCallback(() => {
    return <ListActions
      filter={<Filter />}
    />
  }, []);

  return (
    <List actions={<Actions />}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <ReferenceField  label="Kasutaja" source="userId" reference="users" />
        <NumberField label="Väsimus" source="exhaustion" />
        <NumberField  label="Lihasvalu"source="musclePain" />
        <NumberField label="Unetunnid" source="sleepInHours" />
        <NumberField label="Une kvaliteet" source="sleepQuality" />
        <NumberField label="Treeningtahe" source="willToTrain" />
        <NumberField  label="Stressitase"source="stressLevel" />
        <BooleanField label="Vigastatud" source="injured" />
        <DateField label="Kuupäev" source="savedAt" />
      </Datagrid>
    </List>
  )
}