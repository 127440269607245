import { BooleanField, Button, CreateButton, Datagrid, DateField, EmailField, List, NumberField, TextField, useNotify, useRefresh } from "react-admin"
import { Send } from "@mui/icons-material"
import { Dialog } from "../../components/molecules/dialog";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { TextField as MuiTextField } from "@mui/material";
import { SEND_INVITATION } from "../../api/mutations/user";
import { useForm } from "react-hook-form";
import { Filter } from "./filterForm";
import { ListActions } from "../../components/organisms/listActions";
import { ExportButton } from "../../components/molecules/exportButton";

const InvitationButton = () => {

  const [invitationDialogOpen, setInvitationDialogOpen] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const [invite, { data }] = useMutation(SEND_INVITATION);
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = (data: any) => invite({ variables: { email: data.email } });

  const handleOpenDialog = () => {
    setInvitationDialogOpen(true)
    reset();
  }

  useEffect(() => {
    if (data) {
      notify("Kutse saadetud", { type: "success"} )
      refresh();
    }
  }, [data])

  return (
    <>
      <Button
        onClick={handleOpenDialog}
        label="Kasutaja kutsumine"
        endIcon={<Send />}
      />
      <Dialog
        content={[
          <MuiTextField
            {...register("email")}
            key="user_dialog_email"
            autoFocus
            margin="dense"
            id="name"
            label="E-post"
            type="email"
            fullWidth
            variant="standard" />
        ]}
        contentText="Saada kutse..."
        open={invitationDialogOpen}
        handleSubmit={handleSubmit(onSubmit)}
        setOpen={(open: boolean) => setInvitationDialogOpen(open)} />
    </>
  )
};

export const UserList = () => {

  const Actions = useCallback(() => {
    return <ListActions
      filter={<Filter />}
      exportButton={<ExportButton nestedValueMap={{ account: "isAdmin" }} />}
      buttons={[
        <CreateButton label="Lisa kasutaja" key="create_action" />,
        <InvitationButton key="invitation_action" />
      ]} />
  }, []);

  return (
    <List actions={<Actions />}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="account.username" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List >
  )
}
