import { ReferenceArrayInput as ReferenceArrayInputBase, SelectArrayInput as SelectArrayInputBase, 
  ReferenceArrayInputProps as ReferenceArrayInputPropsBase } from "react-admin"
import { getInSource } from "../../dataProvider/filterLogic";
import { FormInputContainer } from "../atoms/formInputContainer"

interface ReferenceArrayInputProps extends Omit<ReferenceArrayInputPropsBase, "children"> {
  optionText?: string | ((record: any) => string);
}

export const ReferenceArrayInput = (props: ReferenceArrayInputProps) => {

  const { label, reference, source, optionText } = props;

  return (
    <FormInputContainer label={label}>
      <ReferenceArrayInputBase source={getInSource(source)} reference={reference}>
        <SelectArrayInputBase optionText={optionText} fullWidth helperText={false} hiddenLabel={true} label="" />
      </ReferenceArrayInputBase>
    </FormInputContainer>
  );
}