import { SportProfileCreate } from "./create";
import { SportProfileEdit } from "./edit";
import { SportProfileList } from "./list";
import { ISportProfile } from "./types";

const resourceProps = {
    create: SportProfileCreate,
    edit: SportProfileEdit,
    list: SportProfileList,
    recordRepresentation: (record: ISportProfile) => record.name.en ?? record.key,
};

export default resourceProps;