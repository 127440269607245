import { gql } from "@apollo/client";

export const SPORT_PROFILE_PAGINATION = gql`
  query SportProfilePagination($paginationFilter: SportProfilePaginationFilterInput, $page: Int!, $perPage: Int!, $paginationSort: [String]) {
    sportProfilePagination(paginationFilter: $paginationFilter, page: $page, perPage: $perPage, paginationSort: $paginationSort) {
      count
      items {
    
        id:_id
        key
        icon
        externalKeys
        name {
          key
          en
          et
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SPORT_PROFILE_ONE = gql`
  query SportProfileFindOne($id: MongoID!) {
    sportProfileFindById(_id: $id) {
      id:_id
      key
      externalKeys
      icon
      name {
        en
        et
        key
      }
    }
  }
`;

export const SPORT_PROFILE_FIND_MANY = gql`
  query SportProfileFindMany($ids: [MongoID]) {
    sportProfileFindMany(ids: $ids) {
      id:_id
      key
      externalKeys
      icon
      name {
        en
        et
        key
      }
    }
  }
`;

export const SPORT_PROFILE_KEYS = gql`
  query SportProfileKeys {
    sportProfileKeys {
      key
    }
  }
`;