import { Box, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Title } from "react-admin";
import { Area, Bar, CartesianGrid, ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from "recharts";
import { WORKOUTS_ANALYZER_GRAPH } from "../../api/queries/analytics";
import { graphClient } from "../../server/apolloClient";
import { Item } from "../atoms/gridItem";
import { RangeInput } from "../molecules/formRangeInput";
import { ReferenceArrayInput } from "../molecules/formReferenceArrayInput";
import { useForm, FormProvider } from 'react-hook-form';
import { FilterContainer } from '../molecules/filterContainer';
import { compact, get, map, round, sumBy, toNumber, values } from "lodash";
import dayjs from "dayjs";

interface AnalyticsFilterParams {
  from?: string;
  to?: string;
  userIds?: string[];
  groups?: string[];
}


const getData = async (params: AnalyticsFilterParams) => {

  return await graphClient
    .query({
      query: WORKOUTS_ANALYZER_GRAPH,
      variables: params,
    })
    .then((result: any) => result.data?.workoutAnalyzerChart);
}


const barsConfig = {
  heartRate: {
    key: "heartRate",
    name: "Pulsitsoonid",
    set: [
      { dataKey: "hr1", stackId: "a", fill: "green", name: "Tsoon 1" },
      { dataKey: "hr2", stackId: "a", fill: "gold", name: "Tsoon 2" },
      { dataKey: "hr3", stackId: "a", fill: "red", name: "Tsoon 3" },
    ]
  },
  totalLoad: {
    key: "totalLoad",
    name: "RPE Koormus",
    set: [
      { dataKey: "load1", stackId: "c", fill: "green", name: "RPE 1 koormus" },
      { dataKey: "load2", stackId: "c", fill: "gold", name: "RPE 2 koormus" },
      { dataKey: "load3", stackId: "c", fill: "red", name: "RPE 3 koormus" },
    ]
  },
  rpeN: {
    key: "rpeN",
    name: "RPE treeningute arv",
    set: [
      { dataKey: "rpe1n", stackId: "b", fill: "green" },
      { dataKey: "rpe2n", stackId: "b", fill: "gold" },
      { dataKey: "rpe3n", stackId: "b", fill: "red" },
    ]
  },
  rpeLoad: {
    key: "rpeLoad",
    name: "RPE Minutid",
    set: [
      { dataKey: "rpe1minutes", stackId: "c", fill: "green", name: "RPE 1 minutid" },
      { dataKey: "rpe2minutes", stackId: "c", fill: "gold", name: "RPE 2 minutid" },
      { dataKey: "rpe3minutes", stackId: "c", fill: "red", name: "RPE 3 minutid" },
    ]
  },
}

const linesConfig = {
  exhaustion: {
    key: "exhaustion",
    name: "Väsimus",
    set: { name: "Väsimus", dataKey: "exhaustion", stroke: "violet" }
  },
  stressLevel: {
    key: "stressLevel",
    name: "Stressitase",
    set: { name: "Stressitase", dataKey: "stressLevel", stroke: "red"}
  },
  sleepQuality: {
    key: "sleepQuality",
    name: "Une kvaliteet",
    set: { name: "Une kvaliteet", dataKey: "sleepQuality", stroke: "blue"}
  },
  musclePain: {
    key: "musclePain",
    name: "Lihasvalu",
    set: { name: "Lihasvalu", dataKey: "musclePain", stroke: "orange"}
  },
  willToTrain: {
    key: "willToTrain",
    name: "Treeningtahe",
    set: { name: "Treeningtahe", dataKey: "willToTrain", stroke: "lightBlue"}
  },
  sleepInHours: {
    key: "sleepInHours",
    name: "Unetunnid",
    set: { name: "Unetunnid", dataKey: "sleepInHours", stroke: "navy"}
  },
  monotony: {
    key: "monotony",
    name: "Monotoonsus",
    set: { name: "Monotoonsus", dataKey: "monotony", stroke: "gold"}
  },
  tolerance: {
    key: "tolerance",
    name: "Koormustaluvus",
    set: { name: "Koormustaluvus", dataKey: "tolerance", stroke: "darkBlue"}
  },
  subjectiveTotal: {
    key: "subjectiveTotal",
    name: "Subjektivne skoor",
    set: { name: "Subjektivne skoor", dataKey: "subjectiveTotal", stroke: "black"}
  },
  fitness: {
    key: "fitness",
    name: "Akuutne/Krooniline",
    set: { name: "Akuutne/Krooniline", dataKey: "fitness", stroke: "red", strokeWidth: 2 }
  },
}

const leftLinesConfig = {
  acuteLoad: {
    key: "acuteLoad",
    name: "Akuutne koormus",
    set: { dataKey: "acuteLoad", strokeWidth: 2, stroke: "blue", name: "Akuutne koormus" },
  },
  chronicLoad: {
    key: "chronicLoad",
    name: "Krooniline koormus",
    set: { dataKey: "chronicLoad", strokeWidth: 2, stroke: "orange", name: "Krooniline koormus" },
  },
}

export const AnalyticsSection = () => {
  const [chartData, setChartData] = useState();
  const [bars, setBars] = useState<keyof typeof barsConfig | undefined>(undefined);
  const [lines, setLines] = useState<[keyof typeof linesConfig | undefined]>([undefined]);
  const [linesLeft, setLinesLeft] = useState<[keyof typeof leftLinesConfig | undefined]>([undefined]);

  const fetchData = useCallback(async ({
    from,
    to,
    userIds,
    groups,
  }: { from: string, to: string, userIds: string[], groups: string[]}) => {
    const result = await getData({
      from,
      to,
      userIds,
      groups,
    })

    setChartData(result);
  }, [])

  const changeChartBars = useCallback((event: any) => {
    setLinesLeft([undefined])
    setBars(event.target.value as keyof typeof barsConfig)
  }, [])

  const handleChangeLines = useCallback((event:  SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setLines(typeof value === 'string' ? value.split(',') as [keyof typeof linesConfig] : value)
  }, [])

  const handleChangeLeftLines = useCallback((event:  SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setLinesLeft(typeof value === 'string' ? value.split(',') as [keyof typeof leftLinesConfig] : value)
  }, [])

  
  const handleSetLeftLines = useCallback((event:  SelectChangeEvent<any>) => {
    handleChangeLeftLines(event)
    setBars(undefined)
  }, [])



  const form = useForm();

  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      const from = get(values, 'greaterOrEquals?createdAt')
      const to = get(values, 'lessOrEquals?createdAt')
      const userIds = get(values, 'in?userId');
      const groups = get(values, 'in?groups');

      fetchData({
        from,
        to,
        userIds,
        groups,
      })
    }
  };


  const visibleBars = bars && map(get(barsConfig[bars || 'heartRate'], 'set'), (setItem) => (
    <Bar yAxisId="left" {...setItem} barSize={20} />
  ))
  const visibleLines = map(compact(lines), (lineKey) => {
    const setItem = get(linesConfig[lineKey], 'set')
    return (
    <Line dot={false} yAxisId="right" {...setItem} type="monotone"  />
  )})

  const visibleLeftLines = map(compact(linesLeft), (lineKey) => {
    const setItem = get(leftLinesConfig[lineKey], 'set')
    return (
    <Line dot={false} yAxisId="left" {...setItem} type="monotone"  />
  )})

  const totalTime = round(sumBy(chartData, 'duration'));
  const totalCount = sumBy(chartData, (item: any) => item.rpe1n + item.rpe2n + item.rpe3n)
  const totalLoad = round(sumBy(chartData, "totalLoad"))

  const TotalsSection = () => {
    if (!chartData) return null;
    return (
      <Box>
        <Typography variant="h6" fontWeight="bold">Koondandmed</Typography>
        
        <Typography variant="body2"><b>Treeningaeg (minutid):</b> {totalTime}</Typography>
        <Typography variant="body2"><b>Treeningute arv:</b> {totalCount}</Typography>
        <Typography variant="body2"><b>Koormus:</b> {totalLoad}</Typography>
      </Box>
    )
  }

  return (
    <Card sx={{ marginBottom: 4 }}>
      <Title title="Analytics" />
      <Box display="flex" flexDirection="row">
        {
          chartData && (
            <CardContent>
              <ComposedChart width={900} height={250} data={chartData}>
                <XAxis dataKey="name" />
                <YAxis yAxisId="left" type="number"  />
                <YAxis orientation="right" yAxisId="right" domain={[0, 'dataMax']} type="number" />
                <Tooltip />
                <Legend />
                <CartesianGrid stroke="#f5f5f5" />
                {visibleBars}
                {visibleLeftLines}
                {visibleLines}
              </ComposedChart>
            </CardContent>
          )
        }
       <TotalsSection />
      </Box>
        

        <Grid>
          <Item padding={2}>
            <FormControl sx={{ minWidth: 150 }} >
              <InputLabel id="bars-select-label">Tulbad</InputLabel>
              <Select
                labelId="bars-select-label"
                id="bars-select"
                value={bars || ''}
                label="Tulbad"
                onChange={changeChartBars}
              >
              {map(values(barsConfig), (value) => (
                <MenuItem value={value.key}>{value.name}</MenuItem>
              ))}
              </Select>
              </FormControl>
              <FormControl sx={{ marginLeft: 1, width: 150 }}>
                <InputLabel id="lines-left-multiple-label">Koormus</InputLabel>
                <Select
                  labelId="lines-left-multiple-label"
                  id="lines-left-multiple-name"
                  multiple
                  value={linesLeft}
                  onChange={handleSetLeftLines}
                  input={<OutlinedInput label="Name" />}
                  // MenuProps={MenuProps}
                >
                  {map(values(leftLinesConfig), (value) => (
                    <MenuItem
                      key={value.key}
                      value={value.key}
                      // style={getStyles(name, personName, theme)}
                    >
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ marginLeft: 1, width: 150 }}>
                <InputLabel id="demo-multiple-name-label">Jooned</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={lines}
                  onChange={handleChangeLines}
                  input={<OutlinedInput label="Name" />}
                  // MenuProps={MenuProps}
                >
                  {map(values(linesConfig), (value) => (
                    <MenuItem
                      key={value.key}
                      value={value.key}
                      // style={getStyles(name, personName, theme)}
                    >
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
        </Grid>

        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FilterContainer>
            <Grid container item xs={12}>
              <Item>
                  <RangeInput type="date" source="createdAt" label="Periood" />
                </Item>
                <Item md={8}>
                  <ReferenceArrayInput label="Sportlased" source="userId" reference="users"
                    optionText={(record: any) => `${record.firstName} ${record.lastName}`} />
                </Item>
                <Item md={8}>
                  <ReferenceArrayInput label="Grupp" source="groups" reference="userGroups"
                    optionText={(record: any) => record.name.et} />
                </Item>
              </Grid>
            </FilterContainer>
          </form>
        </FormProvider>
    </Card>
  )
}
