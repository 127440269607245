import { Dialog as BaseDialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { useMemo } from "react";

interface SendInvitationDialogProps {
  confirmButtonText?: string;
  content: JSX.Element[];
  contentText: string;
  open: boolean;
  handleSubmit?: (data: any) => void;
  confirmClick?: () => void;
  setOpen: (open: boolean) => void;
}

export const Dialog = (props: SendInvitationDialogProps) => {

  const { confirmButtonText, content, contentText, open, confirmClick, handleSubmit, setOpen } = props;

  const isForm = useMemo(() => !!handleSubmit, [handleSubmit]);

  const modalContent = useMemo(() => {
    return <>
      <DialogContent>
        <DialogContentText>
          {contentText}
        </DialogContentText>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          type={isForm ? "submit" : "button"}
          onClick={() => {
            if (confirmClick) { confirmClick(); }
            setOpen(false);
          }}>{confirmButtonText ?? "OK"}</Button>
      </DialogActions>
    </>
  }, [confirmButtonText, confirmClick, content, contentText, isForm, setOpen])

  return (
    <BaseDialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>New user</DialogTitle>
      {isForm ? 
        <form onSubmit={handleSubmit}>
          {modalContent}
        </form>
        : modalContent
      }
    </BaseDialog>
  );
}