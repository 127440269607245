import { UserGroupCreate } from "./create";
import { UserGroupEdit } from "./edit";
import { UserGroupList } from "./list";
import { IUserGroup } from "./types";

const resourceProps = {
    create: UserGroupCreate,
    edit: UserGroupEdit,
    list: UserGroupList,
    recordRepresentation: (record: IUserGroup) => record.name.en ?? record.key,
};

export default resourceProps;