import { UserList } from './list';
import { UserEdit } from './edit';
import { UserCreate } from './create';
import { IUser } from './types';

const resourceProps = {
    create: UserCreate,
    edit: UserEdit,
    list: UserList,
    recordRepresentation: (record: IUser) => `${record.firstName} ${record.lastName}`
};

export default resourceProps;