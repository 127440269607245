import { DateField, ListButton, ReferenceField, Show, SimpleShowLayout, TextField, Toolbar } from "react-admin"

const TopBar = () => (
  <Toolbar sx={{ justifyContent: "end" }}>
    <ListButton />
  </Toolbar>
);

export const WorkoutShow = () => {

  return (
    <Show actions={<TopBar />}>
      <SimpleShowLayout>
        <ReferenceField source="userId" reference="users" />
        <ReferenceField source="sportProfileId" reference="sportProfiles" />
        <DateField source="start" showTime />
        <TextField source="duration" />
        <TextField source="distance" />
        <TextField source="intensity" />
        <TextField source="tempo" />
        <TextField source="zones" />
        <TextField source="comment" />
      </SimpleShowLayout>
    </Show>
  )
}
