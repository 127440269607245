import { Box, Typography } from "@mui/material";
import { Edit, ListButton, SimpleForm, Toolbar } from "react-admin";
import { FormFooterBar } from "../../components/molecules/formFooterToolbar";
import { SportProfileForm } from "./sportProfileForm";

const EditActions = () => (
  <Toolbar sx={{ justifyContent: "end" }}>
    <ListButton />
  </Toolbar>
);

export const SportProfileEdit = () => {
  return (
    <Edit actions={<EditActions />} redirect={false}>
      <SimpleForm title="Edit user" toolbar={<FormFooterBar />}>
        <Box display="flex" justifyContent="space-between" alignItems="baseline" width="100%" mb={2}>
          <Typography variant="h5">Edit sport profile</Typography>
        </Box>
        <SportProfileForm create={false} />
      </SimpleForm>
    </Edit>
  )
}
