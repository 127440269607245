import { useForm, FormProvider } from 'react-hook-form';
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { useListContext } from 'react-admin';
import { FilterContainer } from '../molecules/filterContainer';

interface FilterFormProps {
  children: JSX.Element | JSX.Element[];
}

export const FilterForm = (props: FilterFormProps) => {

  const { children } = props;

  const {
    filterValues,
    setFilters,
  } = useListContext();

  const form = useForm({
    defaultValues: filterValues,
  });

  const onSubmit = (values: any) => {
    if (Object.keys(values).length > 0) {
      setFilters(values, null);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterContainer>
          {children}
        </FilterContainer>
      </form>
    </FormProvider>
  );
};