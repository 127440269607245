import { BooleanField, DateField, ListButton, NumberField, ReferenceField, Show, SimpleShowLayout, TextField, Toolbar } from "react-admin"

const TopBar = () => (
  <Toolbar sx={{ justifyContent: "end" }}>
    <ListButton />
  </Toolbar>
);

export const SubjectiveDataShow = () => {

  return (
    <Show actions={<TopBar />}>
      <SimpleShowLayout>
        <ReferenceField source="userId" reference="users" />
        <DateField source="savedAt" />
        <BooleanField source="injured" />
        <BooleanField source="exhaustion" />
        <NumberField source="musclePain" />
        <NumberField source="sleepInHours" />
        <NumberField source="sleepQuality" />
        <NumberField source="willToTrain" />
        <NumberField source="stressLevel" />
        <TextField source="comment" />
      </SimpleShowLayout>
    </Show>
  )
}