import { LOGIN } from "./api/mutations/login";
import { graphClient } from "./server/apolloClient";

interface LoginRequest {
  username: string;
  password: string;
}

const authProvider = {
  login: ({ username, password }: LoginRequest) => {
    return Promise.resolve(graphClient.mutate({
      mutation: LOGIN,
      variables: {
        email: username,
        password: password,
        isAdminLogin: true
      }
    }).then(res => {
      const loginData = res.data.login;
      if (loginData.token) {
        localStorage.setItem("token", loginData.token);
        if(loginData.user) {
          localStorage.setItem("user", JSON.stringify(loginData.user));
        }
        return true;
      }
      return false;
    }).catch(() => {
      throw new Error('Network error')
    }))
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject()
  },
  checkError: (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: () => {
    const user = localStorage.getItem("user");
    if (!!user) {
      const { accountId, email, firstName, lastName } = JSON.parse(user);
      const name = `${firstName ?? ""} ${lastName ?? ""}`.trim();
      return Promise.resolve({
        id: accountId,
        fullName: !!name ? name : email,
      });
    } else {
      return Promise.reject();
    }
  },
  getPermissions: () => Promise.resolve('')
}

export default authProvider;
