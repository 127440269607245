import { gql } from "@apollo/client";

export const USER_PAGINATION = gql`
  query UserPagination($paginationFilter: UserPaginationFilterInput, $page: Int!, $perPage: Int!, $paginationSort: [String]) {
    userPagination(paginationFilter: $paginationFilter, page: $page, perPage: $perPage, paginationSort: $paginationSort) {
      count
      items {
        id:_id
        firstName
        lastName
        countryCode
        birthday
        gender
        height
        weight
        language
        heartRate {
          max
          min
          aerobic
          anaerobic
        }
        groups
        account:accountId {
          isAdmin
          username
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const USER_ONE = gql`
  query Query($id: MongoID!) {
    userFindById(_id: $id) {
      id:_id
      email
      phone
      countryCode
      firstName
      lastName
      birthday
      gender
      height
      weight
      groups
      language
      heartRate {
        max
        min
        aerobic
        anaerobic
      }
      account:accountId {
        isAdmin
      }
      createdAt
      updatedAt
    }
  }
`;

export const USER_FIND_MANY = gql`
  query UserFindMany($ids: [MongoID]) {
    userFindMany(ids: $ids) {
      id:_id
      firstName
      lastName
    }
  }
`;

export const USER_ROLES = gql`
  query UserRoles {
    userRoles {
      name {
        key
        en
        et
      }
      role
    }
  }
`;

export const USER_EDIT = gql`
  query UserRoles {
    userRoles {
      name {
        key
        en
        et
      }
      role
    }
    languages {
      key
      value
    }
  }
`;