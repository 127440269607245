import { get, isArray, isObject, omit, reduce } from "lodash";
import { GetListParams } from "react-admin";
import { SPORT_PROFILE_PAGINATION } from "../api/queries/sportProfile";
import { SUBJECTIVE_DATA_PAGINATION } from "../api/queries/subjectiveData";
import { USER_PAGINATION } from "../api/queries/user";
import { USER_GROUPS_PAGINATION } from "../api/queries/userGroups";
import { WORKOUT_PAGINATION } from "../api/queries/workout";
import { graphClient } from "../server/apolloClient";
import { TFilterComparison, TFilterValue } from "./filterLogic";

interface Filter {
  path: string;
  value: any;
}

interface FilterQueryUnit {
  [x: string]: TFilterValue;
  fieldKey: string;
}

export const getList = async (resource: string, params: GetListParams) => {

  const { filter, pagination } = params;

  const filterPaths = reduce(filter, (result, current, key) => {

    let pathSequence: string[] = [key];
    const getPathKey = (v: any, k: string) => {
      const value = get(v, pathSequence.join("."));
      pathSequence.push(k);
      if (!!value) {
        getPathKey(v, Object.keys(value)[0]);
      }
    };

    if (isObject(current) && !isArray(current)) {
      getPathKey(current, Object.keys(current)[0]);
    }

    // prop1: { prop2: ...} -> prop1.prop2....
    let path = pathSequence.reduce((r, v, i) => r + (i === 0 ? v : v === "id" ? "Id" : `.${v}`), "");
    let value: any = get(current, pathSequence.slice(1)) ?? current;

    // hack: convert datetime time to seconds
    if (path.startsWith("time?")) {
      path = path.substring(5);
      const d = new Date(value);
      value = d.getHours() * 3600 + d.getMinutes() * 60 + d.getSeconds();
    }

    return [...result, { path, value }];
  }, [] as Filter[]);

  const { page, perPage } = pagination;

  return graphClient
    .query({
      query: getPaginationQuery(resource),
      variables: {
        paginationSort: [`${params.sort.field}_${params.sort.order === "ASC" ? "asc" : "desc"}`],
        paginationFilter: getQueryFilter(filterPaths),
        page,
        perPage,
      }
    })
    .then((result: any) => {
      return paginationData(result, resource);
    });
}

const getQueryFilter = (filters: Filter[]) => {

  return filters.reduce((result, f) => {
    const split = f.path.split("?");
    const hasComparer = split.length > 1;
    const fieldKey = hasComparer ? split[1] : f.path;
    const comparison = hasComparer ? split[0] as TFilterComparison : "equals";
    const filters = getFilter(fieldKey, f.value, comparison);

    if (filters.length > 1) {
      result.or = [...result.or, ...filters]
    } else {
      result.and = [...result.and, ...filters]
    }

    return result;
  }, { and: [], or: [] } as { and: FilterQueryUnit[], or: FilterQueryUnit[] });
}

const getPaginationQuery = (resource: string) => {

  switch (resource) {
    case "sportProfiles": return SPORT_PROFILE_PAGINATION;
    case "users": return USER_PAGINATION;
    case "userGroups": return USER_GROUPS_PAGINATION;
    case "workouts": return WORKOUT_PAGINATION;
    case "subjectiveDatas": return SUBJECTIVE_DATA_PAGINATION;
    default: throw new Error(`Unknow resource '${resource}' for pagination query`);
  }
}

const paginationData = (result: any, resource: string) => {
  // cut "s" from the end to match pagination query pattern
  const resultname = resource.slice(0, -1) + "Pagination";
  return {
    data: result.data[resultname]?.items.map((i: object) => omit(i, ["__typename"])),
    total: result.data[resultname]?.count,
  };
}

export const getFilter = (key: string, value: TFilterValue, comparison: TFilterComparison) => {
  return key.split("$").map(k => {
    return {
      fieldKey: k,
      [comparison]: value,
    }
  });
}