import { Box, Typography } from "@mui/material";
import { Create, ListButton, SimpleForm, Toolbar } from "react-admin";
import { FormFooterBar } from "../../components/molecules/formFooterToolbar";
import { UserForm } from "./userForm";

const TopBar = () => (
  <Toolbar sx={{ justifyContent: "end" }}>
    <ListButton />
  </Toolbar>
);

export const UserCreate = () => {

  return (
    <Create redirect="edit" title="Loo uus kasutaja" actions={<TopBar />}>
      <SimpleForm toolbar={<FormFooterBar />}>
        <Box display="flex" justifyContent="space-between" alignItems="baseline" width="100%" mb={2}>
          <Typography variant="h5">Loo uus kasutaja</Typography>
        </Box>
        <UserForm create={true} />
      </SimpleForm>
    </Create>
  )
}