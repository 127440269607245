import { omit } from "lodash";
import { CreateParams } from "react-admin";
import { CREATE_SPORT_PROFILE } from "../api/mutations/sportProfile";
import { CREATE_USER, getUserCreateRecord } from "../api/mutations/user";
import { CREATE_USER_GROUP, getUserGroupCreateRecord } from "../api/mutations/userGroup";
import { graphClient } from "../server/apolloClient";

export const create = (resource: string, params: CreateParams) => {

  const create = getCreateMutation(resource);

  return graphClient.mutate({
    mutation: create.gql,
    variables: { record: create.getData(omit(params.data, ["__typename"])) }
  }).then((result: any) => {
    return createOneData(result, resource);
  }).catch((e: any) => {
    throw new Error(e.message)
  })
}

const getCreateMutation = (resource: string) => {
  switch (resource) {
    case "sportProfiles": return {
      gql: CREATE_SPORT_PROFILE,
      getData: (data: any) => getKeyData(data),
    };
    case "userGroups": return {
      gql: CREATE_USER_GROUP,
      getData: (data: any) => getKeyData(data),
    };
    case "users": return {
      gql: CREATE_USER,
      getData: (data: any) => getUserCreateRecord(data),
    };
    default: throw new Error(`Unknown resource '${resource}' for create mutation`);
  }
}

const createOneData = (result: any, resource: string) => {
  const resultname = resource.slice(0, -1) + "CreateOne";
  return {
    data: result.data[resultname].record || result.data[resultname],
  };
}

const getKeyData = (data: any) => {
  data.name.key = data.key;
  delete data.key_new;
  return data;
}