import { gql } from "@apollo/client";

export const WORKOUT_EXPORT_QUERY = gql`
query WorkoutsExport($to: Date, $from: Date, $userIds: [String], $groups: [String], $sportProfileIds: [String]) {
  workoutsExport(to: $to, from: $from, userIds: $userIds, groups: $groups, sportProfileIds: $sportProfileIds) {
    distance
    duration
    firstName
    lastName
    load
    rpe
    sportProfileId
    sportProfileName
    start
    subjectiveExhaustion
    userId
    tempo
    subjectiveScore
    subjectiveMusclePain
    subjectiveSleepHours
    subjectiveSleepQuality
    subjectiveWillToTrain
    subjectiveStressLevel
    subjectiveInjured
    hrZone1
    hrZone2
    hrZone3
    rpe1Minutes
    rpe2Minutes
    rpe3Minutes
  }
}
`