import { Box, Typography } from "@mui/material";
import { Create, ListButton, SimpleForm, Toolbar } from "react-admin";
import { FormFooterBar } from "../../components/molecules/formFooterToolbar";
import { SportProfileForm } from "./sportProfileForm";

const TopBar = () => (
  <Toolbar sx={{ justifyContent: "end" }}>
    <ListButton />
  </Toolbar>
);

export const SportProfileCreate = () => {

  return (
    <Create title="New sport profile" actions={<TopBar />}>
      <SimpleForm toolbar={<FormFooterBar />}>
        <Box display="flex" justifyContent="space-between" alignItems="baseline" width="100%" mb={2}>
          <Typography variant="h5">Create sport profile</Typography>
        </Box>
        <SportProfileForm create={true} />
      </SimpleForm>
    </Create>
  )
}