import { SelectInputProps, SelectInput as SelectInputBase } from "react-admin"
import { FormInputContainer } from "../atoms/formInputContainer";

export const SelectInput = (inputProps: SelectInputProps) => {

  const { label, ...props } = inputProps;

  return (
    <FormInputContainer label={label}>
      <SelectInputBase {...props} hiddenLabel={true} label="" helperText={false} />
    </FormInputContainer>
  );
}