import { gql } from "@apollo/client";

export const WORKOUTS_ANALYZER_GRAPH = gql`
  query WorkoutAnalyzerChart($to: Date, $from: Date, $userIds: [String], $groups: [String]) {
    workoutAnalyzerChart(to: $to, from: $from, userIds: $userIds, groups: $groups) {
      name
      duration
      load1
      load2
      load3
      totalLoad
      acuteLoad
      chronicLoad
      tolerance
      monotony
      rpe1n
      rpe2n
      rpe3n
      rpe1minutes
      rpe2minutes
      rpe3minutes
      hr1
      hr2
      hr3
      stressLevel
      sleepQuality
      musclePain
      willToTrain
      sleepInHours
      exhaustion
      subjectiveTotal
      fitness
    }
  }
`;