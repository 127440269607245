import { ChevronLeft } from "@mui/icons-material";
import { AppBar, Box, Button, Container, Grid, IconButton, Typography } from "@mui/material";
import Markdown from "markdown-to-jsx";


export const Privacy = () => (
  <>
    <AppBar
      sx={{
        display: "flex",
        flexDirection: "row",
        height: 60,
        backgroundColor: "#2C5697",
        alignItems: "center"
      }}
    >
      <Button sx={{ color: "white"}}>
        <ChevronLeft />
        <Typography>Avalehele</Typography>
      </Button>
    </AppBar>
    <Container >
    <Box mt={10} >
      <Markdown>
{
`
# Kasutustingimused

## RPE Trainer rakendus

### Sissejuhatus

RPE Trainer pakub teenuseid, mis võimaldavad Kasutajatel hoiustada ja analüüsida treeningute andmeid, andmeid treeningute ja sportliku vormi kohta ning muud sisu, mida Kasutaja üles laeb, sünkroniseerib teiste kontodega või oma kasutajakonto kaudu käsitsi sisestab.

### 2. Mõisted

Järgnevaid mõisteid kasutatakse käesolevas Kasutustingimustes ja nendest tekkivates pooltevahelistes suhetes:

Isikuandmed –informatsioon, mis on seotud tuvastatud või kaudselt tuvastatava füüsilise isikuga;

Kasutaja – Isik või isikud, kes kasutavadRPE Traineri teenust ja kes võib olla erinevates rollides. Rollid on täpsemalt kirjeldatud punktis.

Teadlane -

Teenus – RPE tarkvara, mida kasutavad Kasutajad ja Teadlased ning mis asub nii veebikeskkonnas kui mobiiliäpis

Kasutustingimused – hõlmab käesolevat lepingut koos kõigi käesolevate ja tulevaste muudatuste ja lisadega

Privaatsuspolitiika – andmete töötlemist ja kohustusi kajastavate reeglite kogum, mis on siduv nii Kasutaja kui teenusepakkuja jaoks

GDPR – Euroopa Liidu Isikuandmete kaitse üldmäärus (European Union's General Data Protection Regulation (EL) 2016/679)

### 3. Kasutajad ja rollid

3.1. Sportlane

3.1.1 Võimalus sisestada tarkvarasse treeningutega seotud andmeid, mis võivad sisaldada nt andmeid treeningu kohta (distants, kiirus vms), enesetunde (lihasvalu, stressitase vms). Samuti on kasutajal võimalus andmete automaatne sünkroniseerimine erinevate treeningutarkvara pakkuvate kolmandate osapooltega, andes selleks RPE Trainerile loa vastavaid andmeid pärida.

3.2. Teadlane

3.2.1 Teadlane analüüsib täiendavalt sportlaste poolt sisestatavaid andmeid ning tagasisidestab neid vastavalt kokkulepitule kas sportlasele või tema treenerile. Teadlane on kohustatud tagama analüüsitud andmete turvalisuse ja esita neid kolmandale osapoolele.

3.2.2 Vastavalt kokkulepetele võib teadlane kogutavaid andmeid kasutada anonümiseeritud kujul ka erinevates teadustöödes, kuvades andmeid keskmiste väärtustena ning tagades kõigil juhtudel Sportlase anonüümsuse.

### 4. Kasutaja kohustused

4.1. Tarkvara kasutades peab kasutama kõiki vajalikke meetmeid, et tagada Teenusesse sisestavate isikuandmete turvalisus. Sa ei tohi ei oma kasutajanime ja parooli kui ka ligipääsu, mis sisaldavad teie kohta käivat infot kolmandate osapooltega jagada ning vastutad täielikult nende turvalisuse eest, valides ise nende hoiustamise viisi. Kõik toimingud, mis on läbi viidud kasutajanime ja parooli kasutades või autentitud mobiiliäppi kaudu loetakse Sportlase läbiviiduks, välja arvatud juhul, kui Sa oled RPE Trainerit eelnevalt teavitanud kolmandate osapoolte ligipääsemisest Sinu kasutajanimele ja paroolile või nende kadumisest. Te oled ainuisikuliselt vastutav kõigi oma konto kaudu tehtud tegevuste ja nende tagajärgede eest Teenuse kasutamise raamistikus või sellega seonduvalt.

4.3. RPE Traineiri kasutamiseks pead Sa olema vähemalt X aastane või Sul peab olema selleks kas lapsevanema või hooldaja nõusolek.

### 5. RPE Traineri kohustused

5.1. RPE Trainer võimaldab Kasutajatele Teenuse kasutamise, välja arvatud juhul, kui on ilmnenud asjaolud, mis ei allu RPE Traineri kontrollile.

5.2. RPE Trainer kasutab kõiki mõistlikke nii tehnilisi kui ka organisatsioonilisi meetmeid, et kõiki sisalduvaid andmeid kaitsta.

5.3. RPE Trainer hoiustab ja töötleb kõiki konto andmeid vastavalt Privaatsuspoliitikale ja kohaldatavatele õigusaktidele.

5.4. RPE Trainer'it loetakse andmete volitatud töötlejaks oma Kasutajate nimel ja töötlemist reguleerivad käesolevad Kasutustingimused.

5.4.1. Isikuandmete töötlemine toimub juhul, kui Kasutaja otsustab oma andmeid rakenduses hoiustada.

5.4.2. RPE Trainer kasutab kõiki asjakohaseid meetmeid, et tagada andmete töötlemise turvalisus.

5.4.3. RPE Trainer tagab, et ligipääs isikuandmetele oleks ainult nendel teadlastel, kellele see on vajalik nende tööülesannete sooritamiseks ning kes on end sidunud konfidentsiaalsusnõuetega.

5.4.4. Kasutaja valikul RPE Trainer kas kustutab või tagastab kõik isikuandmed Kasutajale, kui andmete töötlemisega seotud teenuste pakkumine on lõppenud, ning kustutab kõik olemasolevad koopiad, välja arvatud juhul, kui Euroopa Liidu või selle liikmeriikide seadused nõuavad isikuandmete säilitamist.

5.4.5. RPE Trainer kasutab all töötlejaid juhtudel, mis on vajalikud rakenduse normaalseks funktsioneerimiseks ning ka nendel juhtudel rakenduvad andmete privaatsuspoliitika tingimused ja konfidentsiaalsusnõuded.

### 6. Muud tingimused

6.1. Teadlased ja RPE Trainer ei vastuta õnnetuste, vigastuste ega otseste või kaudsete kahjude eest, mis võivad Kasutajale osaks saada treeningute käigus või rakenduse teenuste kasutamise tõttu, mis on tingitud näiteks, kuid mitte piiritledes: a) Teenuse seisakutest ja häiretest; b) andmekadudest; c) teiste Kasutajate poolt jagatud ebatäpsest, ebausaldusväärsest või ohtlikust infost või failidest; d) rakenduse mittevastavusest Kasutaja nõudmistele.

6.2. Teenuste kasutamine (või kasutada püüdmine) muude vahendite kaudu, kui Sportlyzeri poolt pakutav kasutujaliides, on keelatud. Eriti rangelt on keelatud Teenuste kasutamine (või kasutada püüdmine) automatiseeritud meetmete kaudu (sealhulgas kasutades skripte või web crawler'eid). See ei kehti nende reeglite kohta, mis on välja toodud Teenustes asuvates robots.txt failides.

6.4. RPE Trainer jätab endale õiguse Teenus hooldustöödeks lühiajaliselt katkestada, teavitades sellest Kasutajat võimalusel 2 päeva ette.

6.5. RPE Trainer jätab endale õiguse ükskõik mis põhjusel Teenust või rakendust muuta või selle osutamine lõpetada. Teenuse osutamise lõpetamise korral teavitab RPE Trainer Kasutajat 90 päeva ette.

6.6. RPE Trainer jätab endale õiguse ükskõik mis põhjusel keelduda ükskõik kellele Teenuse osutamisest.

6.7. Käesolevatele Kasutustingimustele ning nende täitmisele, samuti Kasutustingimustes reguleerimata küsimustele, kohaldatakse Eesti Vabariigi õigust.

6.8. Kõik vaidlused, mis tekivad nendest Kasutustingimustest või nendega seonduvalt lahendatakse läbirääkimiste teel või vastavalt hetkel kehtivale menetlusõigusele Eesti Vabariigis.

6.9. Kui mõni tingimus käesolevas lepingus on tühine või kui seda ei loeta lepingu osaks, ei mõjuta see lepingu kehtivust muus osas, mis jääb kehtima ja on täidetav vastavalt lepingutingimustele.

### 7. Intellektuaalne omand ja autoriõigused

7.1. Kasutajale antakse piiratud, tühistatav, personaalne, mitte-üleantav ja mitte-eksklusiivne litsents rakenduse ning selle teenuste kasutamiseks.

7.2. Kasutajal on keelatud ning ei ole lubatud RPE Traineri rakendust või selle osi muuta, rentida, liisida, laenata, müüa, levitada, nendest tuletisi luua, ega mitte ühelgi muul moel üritada lähtekoodist väljavõtteid teha.

7.3. Mitte miski käesolevates Kasutustingimustes ei anna Kasutajale õigust kasutada ükskõik millist RPE Trainerinime.

### 8. Hinnastamine

Info hinnastamise kohta on leitav aadressil ......

### 9. Kasutamise lõpetamine

9.1. Kasutaja võib antud lepingu igal ajahetkel lõpetada, kustutades oma kasutajakonto.

9.2. Kuidagi peaks ka märkima, et anonüümsed andmed jäävad meile teadustööks kasutamiseks...

9.3. RPE Trainer võib nende Kasutustingimuste alusel lepingu viivitamatult üles öelda, kui RPE Trainer on veendunud, et Kasutaja käitub või on käitunud viisil, mis on negatiivselt mõjutanud või võib potentsiaalselt RPE Trainerit ja/või Teenuse või teiste Kasutajate mainet kahjustada, või kui Kasutaja on rikkunud antud Kasutustingimustes seatud kohustusi.

### 10. Kasutustingimuste uuendused

RPE Trainer jätab endale õiguse käesolevates Kasutustingmustes muudatusi teha, edastades muudatused Kasutajale e-maili teel ja/või saates Kasutajatele eelneva kokkuvõtva teavituse muudatustest e-mailile või läbi veebilehe.
`
}
      </Markdown>
    </Box>
  </Container>
  </>
);

