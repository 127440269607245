import { gql } from "@apollo/client";
import { map } from "lodash";

export const CREATE_SPORT_PROFILE = gql`
  mutation Mutation($record: SportProfileInput) {
    sportProfileCreateOne(record: $record) {
      record {
        id:_id
        key
        icon
        name {
          en
          et
          key
        }
      }
    }
  }
`;

export const UPDATE_SPORT_PROFILE = gql`
  mutation SportProfileUpdateById($id: MongoID!, $record: UpdateByIdSportProfileInput!) {
    sportProfileUpdateById(_id: $id, record: $record) {
      id:recordId
    }
  }
`;

export const getProfileUpdateRecord = (d: any) => {
  return {
    key: d.key,
    icon: d.icon,
    externalKeys: map(d.externalKeys, (value) => value.key),
    name: {
      key: d.name.key,
      et: d.name?.et,
      en: d.name?.en,
    },
  }
}


