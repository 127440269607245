import { Box, Paper, Stack, Typography, Button } from "@mui/material";

import blob from './blob.svg'

export const Landing = () => (
  <>
      <Box height="100vh" flexDirection="column" display="flex" flexGrow={1} justifyContent="center" alignItems="center">
        <Paper
          elevation={0}
          sx={{
            width: "100vw",
            height: "100vh",
            backgroundImage: `url(${blob})`,
            backgroundSize: "100vw 100vh",
            backgroundPositionX: '15vw',
            backgroundRepeat: "no-repeat",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack mr={25} spacing={4}>
            <Typography fontWeight="300" variant="h1">
              RPE Trainer 
            </Typography>
            <Typography variant="body1" maxWidth={600}>
              Rakendus on loodud pakkumaks sportlastele efektiivsemat tagasisidet
              ning aitab analüüsida treeningute koormuse mõju töövõimele ja üldisele seisundile. 
            </Typography>
            <Typography  maxWidth={600} variant="body1">
              Rakendus on loodud Tartu Ülikooli teadlaste poolt sportlaste treeningute juhtimiseks
              ning andmete kogumiseks teadustöö ning treeningute tagasisidestamise eesmärgil. 
            </Typography>

            <Button sx={{ width: 300, paddingY: 1 }} variant="contained">Alusta</Button>
          </Stack>
          <img src={"/mobile.png"} alt="App preview" />
        </Paper>
      </Box>
  </>
);

