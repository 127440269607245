import { gql } from "@apollo/client";

export const CREATE_USER_GROUP = gql`
  mutation UserGroupCreateOne($record: CreateOneUserGroupInput!) {
    userGroupCreateOne(record: $record) {
      record {
        version
        deletedAt
        id: _id
        createdAt
        updatedAt
        key
        icon
        name {
          key
          en
          et
        }
      }
    }
  }
`;

export const UPDATE_USER_GROUP = gql`
mutation UserGroupUpdateById($id: MongoID!, $record: UpdateByIdUserGroupInput!) {
  userGroupUpdateById(_id: $id, record: $record) {
    record {
      version
      deletedAt
      id: _id
      createdAt
      updatedAt
      key
      icon
      name {
        key
        en
        et
      }
    }
  }
}
`;

export const getUserGroupUpdateRecord = (d: any) => {
  return {
    key: d.key,
    icon: d.icon,
    name: {
      key: d.name.key,
      et: d.name?.et,
      en: d.name?.en,
    },
  }
}

export const getUserGroupCreateRecord = (d: any) => {
  return {
    key: d.key,
    icon: d.icon,
    name: {
      key: d.name.key,
      et: d.name?.et,
      en: d.name?.en,
    },
  }
}




