import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!, $isAdminLogin: Boolean) {
    login(email: $email, password: $password, isAdminLogin: $isAdminLogin) {
    token
    user {
      id: _id
      account:accountId {
        isAdmin
      }
      firstName
      lastName
      email
      lang: language
    }
  }
}`