import { gql } from "@apollo/client";

export const WORKOUT_PAGINATION = gql`
  query Query($paginationFilter: WorkoutPaginationFilterInput, $page: Int!, $perPage: Int!, $paginationSort: [String]) {
    workoutPagination(paginationFilter: $paginationFilter, page: $page, perPage: $perPage, paginationSort: $paginationSort) {
      count
      items {
        id:_id
        userId
        start
        duration
        distance
        intensity
        createdAt
        updatedAt
        sportProfile:sportProfileId {
          id:_id
          key
          name {
            en
            et
            key
          }
        }
      }
    }
  }
`;

export const WORKOUT_ONE = gql`
  query Query($id: MongoID!) {
    workoutFindById(_id: $id) {
      id:_id
      userId
      sportProfile:sportProfileId {
        id:_id
        key
        name {
          en
          et
          key
        }
      }
      start
      duration
      tempo
      distance
      intensity
      zones
      comment
      createdAt
      updatedAt
    }
  }
`;