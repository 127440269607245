import { Datagrid, DateField, FunctionField, NumberField, ReferenceField, TextField } from "react-admin"
import useCurrentUser from "../../hooks/currentUser";
import { SportProfileIcon } from "../sportProfiles/sportProfileForm";
import { Filter } from "./filterForm";
import { useCallback } from "react";
import { ListActions } from "../../components/organisms/listActions";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
import { List } from "../../components/organisms/showList";
import { ExportButton } from "../../components/molecules/exportButton";
dayjs.extend(duration)

const exportFields = [
  "userId",
  "firstName",
  "lastName",
  "sportProfileId",
  "sportProfileName",
  "start",
  "duration",
  "distance",
  "tempo",
  "rpe",
  "load",
  "hrZone1",
  "hrZone2",
  "hrZone3",
  "rpe1Minutes",
  "rpe2Minutes",
  "rpe3Minutes",
  "subjectiveScore",
  "subjectiveExhaustion",
  "subjectiveMusclePain",
  "subjectiveSleepHours",
  "subjectiveSleepQuality",
  "subjectiveWillToTrain",
  "subjectiveStressLevel",
  "subjectiveInjured",
]

export const WorkoutList = () => {

  const { currentLanguage } = useCurrentUser();

  const Actions = useCallback(() => {
    return <ListActions
      exportButton={<ExportButton dataKey="workoutsExport" exportFields={exportFields} />}
      filter={<Filter />}
    />
  }, []);

  return (
    <List actions={<Actions />}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <ReferenceField source="userId" reference="users" label="Kasutaja" />
        <ReferenceField source="sportProfile.id" reference="sportProfiles" label="Spordiprofiil">
          <TextField source={`name[${currentLanguage}]`} />
        </ReferenceField>
        <FunctionField
          render={(record: any) => SportProfileIcon({ sportsKey: record.sportProfile.key })}
        />
      
        <NumberField source="intensity" label="RPE" />
        <FunctionField label="Kestvus"
          render={(record: any) => dayjs.duration(record.duration, 'seconds').format('HH:mm:ss')}
        />
        <NumberField label="Distants" source="distance"  locales="et-ET" />
        <NumberField source="tempo"  locales="et-ET" />
        <DateField label="Treeningu algus" source="start" showTime />
      </Datagrid>
    </List>
  )
}