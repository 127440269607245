import useLocalStorage from "./localStorage";

interface ICurrentUser {
  id: string;
  accountId: string;
  firstName: string;
  lastName: string;
  email: string;
  lang: "en" | "et";
}

function useCurrentUser() {

  const [user, setUser] = useLocalStorage<ICurrentUser | null>("user", JSON.parse(localStorage.getItem("user") ?? ""));

  const setCurrentUser = (changedUser: ICurrentUser) => {
    setUser(changedUser);
  }

  return {
    currentUser: user,
    currentLanguage: user?.lang ?? "en",
    setCurrentUser
  }
}

export default useCurrentUser