import { Box, Typography } from "@mui/material";
import { Button, Edit, ListButton, SimpleForm, Toolbar, useEditContext, useEditController } from "react-admin";
import { Send } from "@mui/icons-material";
import { EditAside } from "../../components/molecules/editAside";
import { UserForm } from "./userForm";
import { Route, useNavigate } from "react-router-dom";
import { FormFooterBar } from "../../components/molecules/formFooterToolbar";
import { IUser } from "./types";
import { useMutation } from "@apollo/client";
import { PASSWORD_RESET } from "../../api/mutations/passwordReset";
import { DELETE_USER } from "../../api/mutations/deleteUser";

const EditActions = () => (
  <Toolbar sx={{ justifyContent: "end" }}>
    <ListButton />
  </Toolbar>
);

export const UserEdit = () => {
  const [mutate, { data }] = useMutation(PASSWORD_RESET)
  const [deleteUser, { data: deleteUserData }] = useMutation(DELETE_USER);
  const navigate = useNavigate();

  const { record } = useEditController<IUser>();

  const handleResetPassword = () => {
    mutate({
      variables: {
        email: record?.email
      }
    })
  }

  const handleDeleteUser = () => {
    deleteUser({
      variables: {
        id: record?.id,
      }
    })
    navigate(-1)
  }

  return (
    <Edit aside={<EditAside />} title={`${record?.firstName || ''} ${record?.lastName || ''}`} actions={<EditActions />} redirect={false}>
      <SimpleForm title="Muuda kasutaja andmeid" toolbar={<FormFooterBar />}>
        <Box display="flex" justifyContent="space-between" alignItems="baseline" width="100%" mb={2}>
          <Typography variant="h5">Muuda kasutaja andmeid</Typography>
          <Button
            color="primary"
            label="Saada parooli taastamise link"
            onClick={handleResetPassword}
            size="medium"
            endIcon={<Send />} />
        </Box>
        <UserForm create={false} />
      </SimpleForm>
      <Box px={2} py={2} display="flex" justifyContent="flex-end">
        <Button onClick={handleDeleteUser} sx={{ color: "red" }} color="secondary" label="Kustuta"  />
      </Box>
    </Edit>
  )
}
