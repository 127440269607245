import { gql } from "@apollo/client";

export const SUBJECTIVE_DATA_PAGINATION = gql`
  query SubjectiveDataPagination($page: Int!, $perPage: Int!, $sort: SortFindManySubjectiveDataInput, $paginationFilter: SubjectiveDataPaginationFilterInput, $paginationSort: [String]) {
  subjectiveDataPagination(page: $page, perPage: $perPage, sort: $sort, paginationFilter: $paginationFilter, paginationSort: $paginationSort) {
      count
      items {
        id:_id
        injured
        musclePain
        exhaustion
        sleepInHours
        sleepQuality
        stressLevel
        willToTrain
        comment
        userId
        savedAt
      }
    }
  }
`;

export const SUBJECTIVE_DATA_ONE = gql`
  query Query($id: MongoID!) {
    subjectiveDataFindById(_id: $id) {
      id:_id
      injured
      musclePain
      exhaustion
      sleepInHours
      sleepQuality
      stressLevel
      willToTrain
      comment
      userId
      savedAt
    }
  }
`;
