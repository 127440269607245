import { Menu } from "react-admin";
import PeopleIcon from '@mui/icons-material/People';
import WorkoutIcon from '@mui/icons-material/Sports';
import FitnessCenter from '@mui/icons-material/FitnessCenter';
import Grading from '@mui/icons-material/Grading';
import Insights from '@mui/icons-material/Insights';


export const AppMenu = (props: any) => (
  <Menu {...props}>
      <Menu.DashboardItem />
      <Menu.Item to="/users" primaryText="Kasutajad" leftIcon={<PeopleIcon />}/>
      <Menu.Item to="/userGroups" primaryText="Kasutajagrupid" leftIcon={<PeopleIcon />}/>
      <Menu.Item to="/workouts" primaryText="Treeningud" leftIcon={<FitnessCenter />}/>
      <Menu.Item to="/sportProfiles" primaryText="Spordialad" leftIcon={<WorkoutIcon />}/>
      <Menu.Item to="/subjectiveDatas" primaryText="Subjektiivne tagasiside" leftIcon={<Grading />}/>
      <Menu.Item to="/analytics" primaryText="Analüüs" leftIcon={<Insights />}/>
  </Menu>
);