import { useQuery } from "@apollo/client"
import { Grid } from "@mui/material"
import { TextInput, BooleanInput, Loading, SelectInput, PasswordInput, ReferenceArrayInput, SelectArrayInput } from "react-admin"
import { USER_EDIT } from "../../api/queries/user"
import { ILanguage, IUserRole } from "./types"

interface UserEditSelects {
  userRoles: IUserRole[];
  languages: ILanguage[];
}

const GridItem = (props: { children?: JSX.Element }) => {
  return (
    <Grid item sm={1} md={1}>
      {props.children}
    </Grid>
  )
}

export const UserForm = (props: { create: boolean }) => {

  const { create } = props;

  const { data: selects, loading: selectsLoading } = useQuery<UserEditSelects>(USER_EDIT);

  if (selectsLoading) {
    return <Loading />
  }

  return (
    <Grid container rowSpacing={1} columnSpacing={2} columns={{ sm: 1, md: 2 }}>
      <GridItem>
        <TextInput label="Eesnimi" source="firstName" required fullWidth />
      </GridItem>
      <GridItem>
        <TextInput label="Perekonnanimi" source="lastName" required fullWidth />
      </GridItem>
      <GridItem>
        <TextInput label="E-post" source="email" required fullWidth autoComplete="off" />
      </GridItem>
      {
        create ?
          <GridItem>
            <PasswordInput label="Salasõna" source="password" fullWidth autoComplete="new-password" />
          </GridItem>
          : <></>
      }
      <GridItem>
        <SelectInput
          source="language"
          label="Keel"
          fullWidth
          required
          choices={(selects?.languages ?? []).map(lang => {
            return {
              id: lang.key,
              name: lang.value
            }
          })} />
      </GridItem>

      {
        !create && (
          <GridItem>
            <ReferenceArrayInput source={"groups"} reference="userGroups">
              <SelectArrayInput optionText={(record: any) => `${record.name.et}`} fullWidth helperText={false} label="Kasutajagrupid" />
            </ReferenceArrayInput>
          </GridItem>
        )
      }
  
      <GridItem>
        <BooleanInput label="Admin kasutaja" source="account.isAdmin" />
      </GridItem>

    </Grid>
  )
}

