import { gql } from "@apollo/client";

export const USER_GROUPS_PAGINATION = gql`
query Query($page: Int!, $perPage: Int!, $sort: SortFindManyUserGroupInput, $paginationFilter: UserGroupPaginationFilterInput, $paginationSort: [String]) {
  userGroupPagination(page: $page, perPage: $perPage, sort: $sort, paginationFilter: $paginationFilter, paginationSort: $paginationSort) {
    count
    items {
      version
      deletedAt
      id: _id
      createdAt
      updatedAt
      key
      icon
      name {
        key
        en
        et
      }
    }
  }
}
`;

export const USER_GROUPS_ONE = gql`
query UserGroupFindById($id: MongoID!) {
  userGroupFindById(_id: $id) {
    version
    deletedAt
    id: _id
    createdAt
    updatedAt
    key
    icon
    name {
      key
      en
      et
    }
  }
}
`;

export const USER_GROUPS_FIND_MANY = gql`
  query UserGroupsFindMany($ids: [MongoID]) {
    userGroupFindMany(ids: $ids) {
      id:_id
      key
      icon
      name {
        en
        et
        key
      }
    }
  }
`;

export const USER_GROUPS_KEYS = gql`
  query SportProfileKeys {
    sportProfileKeys {
      key
    }
  }
`;