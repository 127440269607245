import { Grid } from '@mui/material';
import { FilterForm } from '../../components/organisms/filterForm';
import { RangeInput } from '../../components/molecules/formRangeInput';
import { Item } from '../../components/atoms/gridItem';
import { ReferenceArrayInput } from '../../components/molecules/formReferenceArrayInput';

export const Filter = () => {

  return (
    <FilterForm>
      <Grid container item xs={12}>
        <Item>
          <ReferenceArrayInput source="userId" reference="users" label="Kasutajad"
            optionText={(record: any) => `${record.firstName} ${record.lastName}`} />
        </Item>
        <Item>
          <ReferenceArrayInput source="groups" reference="userGroups" label="Kasutajagrupid"
            optionText="name.et" />
        </Item>
        <Item>
          <ReferenceArrayInput source="sportProfile.id" reference="sportProfiles" label="Sport"
            optionText="name.et" />
        </Item>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item sm={6} md={7} lg={8} xl={9}>
          <Item>
            <RangeInput type="date" source="start" label="Treeningu algus" />
          </Item>
          <Item empty />
        </Grid>
      </Grid>
    </FilterForm>
  );
};