import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation UserCreateOne($record: CreateOneUserWithPasswordInput) {
    userCreateOne(record: $record) {
      id:_id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UserUpdateById($id: MongoID!, $record: UpdateByIdUserInput) {
    userUpdateById(_id: $id, record: $record) {
      id: _id
    }
  }
`;

export const SEND_INVITATION = gql`
  mutation InviteUser($email: String!) {
    inviteUser(email: $email) {
      email
    }
  }
`;

export const getUserUpdateRecord = (d: any) => {
  return {
    firstName: d.firstName,
    lastName: d.lastName,
    email: d.email,
    language: d.language,
    isAdmin: d.account.isAdmin,
    groups: d.groups,
  }
}

export const getUserCreateRecord = (d: any) => {
  return {
    firstName: d.firstName,
    lastName: d.lastName,
    email: d.email,
    language: d.language,
    password: d.password,
    isAdmin: d.account.isAdmin,
    groups: d.groups,
  }
}
