import { UpdateParams } from "react-admin";
import { getProfileUpdateRecord, UPDATE_SPORT_PROFILE } from "../api/mutations/sportProfile";
import { getUserUpdateRecord, UPDATE_USER } from "../api/mutations/user";
import { getUserGroupUpdateRecord, UPDATE_USER_GROUP } from "../api/mutations/userGroup";
import { graphClient } from "../server/apolloClient";

export const update = (resource: string, params: UpdateParams) => {

  const mutation = getUpdateMutation(resource, params);
  return graphClient.mutate({
    mutation: mutation.gql,
    variables: {
      id: params.id,
      record: mutation.record,
    }
  }).then((result: any) => updatedData(result, resource))
}

const getUpdateMutation = (resource: string, params: UpdateParams) => {

  switch (resource) {
    case "sportProfiles": return {
      gql: UPDATE_SPORT_PROFILE,
      record: getProfileUpdateRecord(params.data)
    };
    case "userGroups": return {
      gql: UPDATE_USER_GROUP,
      record: getUserGroupUpdateRecord(params.data)
    };
    case "users": return {
      gql: UPDATE_USER,
      record: getUserUpdateRecord(params.data),
    };
    default: throw new Error(`Unknown resource '${resource}' for update mutation`);
  }
}

const updatedData = (result: any, resource: string) => {
  const resultname = resource.slice(0, -1) + "UpdateById";
  return {
    data: result.data[resultname],
  };
}