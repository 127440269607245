import { Box, Stack, Typography } from "@mui/material"
import { DateField, Labeled, NumberField, SimpleShowLayout, TextField } from "react-admin"

interface EditAsideProps {
  children?: JSX.Element[];
  title?: string;
}

export const EditAside = (props: EditAsideProps) => {

  const { title } = props;

  return (
    <Box sx={{ width: "500px" }}>
      <SimpleShowLayout>
        <Typography variant="h6">{title ?? "Details"}</Typography>
        <Stack>
          <Labeled>
            <TextField source="id" />
          </Labeled>
          <Labeled>
            <TextField source="gender" />
          </Labeled>
          <Labeled>
            <DateField source="birthday" />
          </Labeled>
          <Labeled label="Height (cm)">
            <NumberField source="height" />
          </Labeled>
          <Labeled label="Weight (kg)">
            <NumberField source="weight" />
          </Labeled>
          <Labeled label="Maximum heart rate (bpm)">
            <NumberField source="heartRate.max" />
          </Labeled>
          <Labeled label="Min heart rate (bpm)">
            <NumberField source="heartRate.min" />
          </Labeled>
          <Labeled>
            <DateField source="createdAt" showTime />
          </Labeled>
          <Labeled>
            <DateField source="updatedAt" showTime />
          </Labeled>
        </Stack>
      </SimpleShowLayout>
    </Box>
  )
}
