import { Box, Typography } from "@mui/material"
import { JSXElementConstructor, ReactElement } from "react";

interface FormInputContainerProps {
  children: JSX.Element | JSX.Element[];
  label: string | false | ReactElement<any, string | JSXElementConstructor<any>> | undefined
  labelWidth?: number;
}

export const FormInputContainer = (props: FormInputContainerProps) => {

  const { children, label, labelWidth } = props;

  return (
    <Box display="flex" flex="nowrap" flexDirection="row" alignItems="baseline">
      <Typography variant="caption" width={labelWidth ?? 120}>
        {label}
      </Typography>
      <Box display="flex" flex="nowrap" flexDirection="row" alignItems="baseline">
        {children}
      </Box>
    </Box>
  );
}