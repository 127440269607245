import { useMemo } from "react";
import { TextInput as TextInputBase, TextInputProps as TextInputPropsBase } from "react-admin"
import { getLikeSource } from "../../dataProvider/filterLogic";
import { FormInputContainer } from "../atoms/formInputContainer";

interface TextInputProps extends Omit<TextInputPropsBase, "label"> {
  actionType?: "like";
  label?: string
}

export const TextInput = (inputProps: TextInputProps) => {

  const { actionType, label, source: src, ...props } = inputProps;

  const source = useMemo(() => {
    switch (actionType) {
      case "like": return getLikeSource(src)
      default: return src;
    }
  }, [src, actionType]);

  return (
    <FormInputContainer label={label}>
      <TextInputBase source={source} {...props} hiddenLabel={true} label="" helperText={false} />
    </FormInputContainer>
  );
}