import { Grid } from '@mui/material';
import { FilterForm } from '../../components/organisms/filterForm';
import { TextInput } from '../../components/molecules/formTextInput';
import { Item } from '../../components/atoms/gridItem';
import { RangeInput } from '../../components/molecules/formRangeInput';
import { SelectInput } from '../../components/molecules/formSelectInput';
import { ReferenceArrayInput } from '../../components/molecules/formReferenceArrayInput';

export const Filter = () => {
  return (
    <FilterForm>
      <Grid container item xs={12} sm={6} lg={4} xl={3}>
        <Item>
          <TextInput actionType="like" source="firstName$lastName" label="Name" />
        </Item>
        <Item>
          <TextInput actionType="like" source="email" label="Email" />
        </Item>
        <Item>
          <RangeInput type="number" source="height" label="Height" />
        </Item>
        <Item>
          <RangeInput type="number" source="weight" label="Weight" />
        </Item>
      </Grid>
      <Grid container item xs={12} sm={6} lg={8} xl={9}>
        <Item>
          <SelectInput source="gender" label="Gender"
            choices={[ {id: "male", name: "Male"}, { id: "female", name: "Female"}]} />
        </Item>
        <Item>
          <RangeInput type="date" source="birthday" label="Birthday" />
        </Item>
        <Item>
          <ReferenceArrayInput label="Kasutajagrupid" source="groups" reference="userGroups"
            optionText={(record: any) => `${record.name.et}`} />
        </Item>
        <Item empty />
        <Item empty />
      </Grid>
    </FilterForm >
  );
};