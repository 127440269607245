import { AddOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import {  times } from "lodash";
import { useCallback, useState } from "react";
import { AnalyticsSection } from "../organisms/analytics";


const Analytics = () => {
  const [sectionsCount, setSectionsCount] = useState(1)

  const addSection = useCallback(() => {
    setSectionsCount((count) => count + 1)
  }, [])

  return (
    <>
    <Box pt={4}>
      <Typography variant="h5" marginBottom={4}>Analüütika moodul</Typography>
    </Box>
      
      {times(sectionsCount, (section) => <AnalyticsSection key={section} />)}
     
      <Box marginTop={4}>
        <Button onClick={addSection} variant="text" startIcon={<AddOutlined />}>Lisa graafik</Button>
      </Box>
    </>
  )
}

export default Analytics;