import { Box } from "@mui/material";
import { useCallback, useState } from "react";
import { Button, TopToolbar } from "react-admin";
import ContentFilter from '@mui/icons-material/FilterList';
import { ExportButton } from "../molecules/exportButton";


interface ListActionsProps {
  buttons?: JSX.Element[];
  filter?: JSX.Element;
  exportButton?: JSX.Element | false;
}

export const ListActions = (props: ListActionsProps) => {

  const { buttons, exportButton, filter } = props;
  
  const [filterOpen, setFilterOpen] = useState(false);

  const CustomButtons = useCallback(() => {
    if(buttons) {
      return (<>{buttons}</>);
    }
    return null;
  }, [buttons]);


  const FilterButton = useCallback(() => {
    if (filter) {
      return (
        <Button onClick={() => setFilterOpen(!filterOpen)} label="Filter">
          <ContentFilter />
        </Button>
      )
    }
    return null;
  }, [filterOpen, filter]);

  const Filter = useCallback(() => {
    return filterOpen ? filter ?? null : null
  }, [filterOpen, filter])

  return (
    <Box mb={2} width="100%">
      <TopToolbar>
        <CustomButtons />
        <FilterButton />
        {exportButton === false ? null : exportButton ?? <ExportButton />}
      </TopToolbar>
      <Filter />
    </Box>
  );
}