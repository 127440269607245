import { DeleteManyParams, DeleteParams, GetManyReferenceParams, UpdateManyParams } from "react-admin";
import { create } from "./createProvider";
import { getMany } from "./getManyProvider";
import { getList } from "./listProvider";
import { getOne } from "./oneProvider";
import { update } from "./updateProvider";

export const dataProvider = {
  getList,
  getOne,
  getMany,
  getManyReference: (resource: string, params: GetManyReferenceParams) => {
    throw new Error("'getManyReference' not implemented");
  },
  create,
  update,
  updateMany: (resource: string, params: UpdateManyParams) => {
    throw new Error("'updateMany' not implemented");
  },
  delete: (resource: string, params: DeleteParams<any>) => {
    throw new Error("'delete' not implemented");
  },
  deleteMany: (resource: string, params: DeleteManyParams<any>) => {
    throw new Error("'deleteMany' not implemented");
  },
};