type TFilterStringComparison = "equals" | "not" | "like" | "in" | "notIn" | "inLike" | 
  "lessThan" | "greaterThan" | "greaterOrEquals" | "lessOrEquals" | "between"

type TFilterNumberComparison = "numberEquals" | "numberNot" | "numberIn" | "numberNotIn" | 
  "numberLessThan" | "numberGreaterThan" | "numberGreaterOrEquals" | "numberLessOrEquals" | "numberBetween";

type TFilterBooleanComparison = "booleanEquals";

export type TFilterComparison = TFilterStringComparison | TFilterNumberComparison | TFilterBooleanComparison;

export type TFilterValue = string | number | string[] | number[];

// string comparisons
export const getEqualsSource = (source: string) => getSource("equals", source);
export const getNotSource = (source: string) => getSource("not", source);
export const getLikeSource = (source: string) => getSource("like", source);
export const getInSource = (source: string) => getSource("in", source);
export const getNotInSource = (source: string) => getSource("notIn", source);
export const getInLikeSource = (source: string) => getSource("inLike", source);
export const getLessThanSource = (source: string) => getSource("lessThan", source);
export const getGreaterThanSource = (source: string) => getSource("greaterThan", source);
export const getLessOrEqualsSource = (source: string) => getSource("lessOrEquals", source);
export const getGreaterOrEqualsSource = (source: string) => getSource("greaterOrEquals", source);

// number comparisons
export const getNumberEqualsSource = (source: string) => getSource("numberEquals", source);
export const getNumberNotSource = (source: string) => getSource("numberNot", source);
export const getNumberInSource = (source: string) => getSource("numberIn", source);
export const getNumberNotInSource = (source: string) => getSource("numberNotIn", source);
export const getNumberLessThanSource = (source: string) => getSource("numberLessThan", source);
export const getNumberGreaterThanSource = (source: string) => getSource("numberGreaterThan", source);
export const getNumberLessOrEqualsSource = (source: string) => getSource("numberLessOrEquals", source);
export const getNumberGreaterOrEqualsSource = (source: string) => getSource("numberGreaterOrEquals", source);

// boolean comparisons
export const getBooleanEqauls = (source: string) => getSource("booleanEquals", source);

// custom comparisons
export const getTimeLessOrEqualsSource = (source: string) => getSource("numberLessOrEquals", source, { isTimeInput: true });
export const getTimeGreaterOrEqualsSource = (source: string) => getSource("numberGreaterOrEquals", source, { isTimeInput: true });

const getSource = (comparision: TFilterComparison, source: string, opts?: { isTimeInput: boolean }) => 
  `${opts?.isTimeInput ? "time?" : ""}${comparision}?${source}`;