import { Grid } from '@mui/material';
import { FilterForm } from '../../components/organisms/filterForm';
import { Item } from '../../components/atoms/gridItem';
import { RangeInput } from '../../components/molecules/formRangeInput';
import { BooleanInput } from '../../components/molecules/formBooleanInput';
import { ReferenceArrayInput } from '../../components/molecules/formReferenceArrayInput';

export const Filter = () => {

  return (
    <FilterForm>
      <Grid container item xs={12}>
        <Item md={8}>
          <ReferenceArrayInput label="Kasutajad" source="userId" reference="users"
            optionText={(record: any) => `${record.firstName} ${record.lastName}`} />
        </Item>
        <Item>
          <BooleanInput source="injured" label="Vigastatud" />
        </Item>
      </Grid>
      <Grid container item md={4} xl={3}>
        <Item>
          <RangeInput type="number" source="musclePain" label="Lihasvalu" />
        </Item>
        <Item>
          <RangeInput type="number" source="exhaustion" label="Väsimus" />
        </Item>
        <Item>
          <RangeInput type="number" source="willToTrain" label="Treeningtahe" />
        </Item>
        <Item>
          <RangeInput type="number" source="stressLevel" label="Stressitase" />
        </Item>
      </Grid>
      <Grid container item md={8} xl={9}>
        <Item>
          <RangeInput type="number" source="sleepQuality" label="Une kvaliteet" />
        </Item>
        <Item>
          <RangeInput type="number" source="sleepInHours" label="Unetunnid" />
        </Item>
        <Item>
          <RangeInput type="date" source="savedAt" label="Loomise kuupäev" />
        </Item>
      </Grid>
    </FilterForm>
  );
};