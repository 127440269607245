import { omit } from "lodash";
import { GetManyParams } from "react-admin";
import { SPORT_PROFILE_FIND_MANY } from "../api/queries/sportProfile";
import { USER_FIND_MANY } from "../api/queries/user";
import { USER_GROUPS_FIND_MANY } from "../api/queries/userGroups";
import { graphClient } from "../server/apolloClient";

export const getMany = async (resource: string, params: GetManyParams) => {

  return graphClient
    .query({
      query: getManyQuery(resource).gql,
      variables: {
        ids: params.ids
      }
    })
    .then((result: any) => findManyData(result, resource));
}

const getManyQuery = (resource: string) => {

  switch (resource) {
    case "sportProfiles": return {
      gql: SPORT_PROFILE_FIND_MANY,
    };
    case "userGroups": return {
      gql: USER_GROUPS_FIND_MANY,
    };
    case "users": return {
      gql: USER_FIND_MANY,
    };
    default: throw new Error(`Unknown resource '${resource}' for find many query`);
  }
}

const findManyData = (result: any, resource: string) => {
  const resultname = resource.slice(0, -1) + "FindMany";
  return {
    data: result.data[resultname].map((i: object) => omit(i, ["__typename"])),
  };
}
