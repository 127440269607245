import { useCallback } from "react";
import { CreateButton, Datagrid, FunctionField, List, TextField } from "react-admin"
import { ListActions } from "../../components/organisms/listActions";
import useCurrentUser from "../../hooks/currentUser";
import { UserGroupIcon } from "./userGroupForm";

export const UserGroupList = () => {

  const { currentLanguage } = useCurrentUser();

  const Actions = useCallback(() => {
    return <ListActions
      buttons={[<CreateButton key={"profile_create_btn"} />]}
    />
  }, []);

  return (
    <List actions={<Actions />}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <FunctionField render={(record: any) => UserGroupIcon({ sportsKey: record.icon })} />
        <TextField source="key" />
        <TextField source={`name[${currentLanguage}]`} label="Grupi nimi" />
        <TextField source="createdAt" />
        <TextField source="updatedAt" />
      </Datagrid>
    </List >
  )
}
