
import { Grid } from "@mui/material"
import { ArrayInput, SelectInput, SimpleFormIterator, TextInput, useRecordContext } from "react-admin"
import {
  mdiBadminton, mdiBasketball, mdiBike, mdiBoxingGlove, mdiDumbbell, mdiHandball, mdiKabaddi, mdiKayaking, mdiMeditation, mdiRollerblade, mdiRowing, mdiRun, mdiSkate, mdiSkiCrossCountry, mdiSwim, mdiTennis, mdiYoga, mdiWeightLifter, mdiHuman, mdiSoccer, mdiVolleyball, 
} from '@mdi/js'
import { Stack } from "@mui/system"
import { Item } from "../../components/atoms/gridItem"
import Icon from "@mdi/react"
import { keys } from "lodash"

export const SportProfileForm = (props: { create: boolean }) => {

  const { create } = props;

  return (
    <Grid container item md={6} lg={4} xl={3}>
      <Item>
        <TextInput source={"key"} fullWidth label="key" />
      </Item>
      {["et", "en"].map(k => {
        return (
          <Item key={"name_item_" + k}>
            <TextInput source={`name.${k}`} fullWidth label={`Name (${k.toUpperCase()})`} />
          </Item>
        )
      })}
      <Item>
        <SportProfileIconSelect source="icon" label="Icon" />
      </Item>
      <Item>
        <ArrayInput
          source="externalKeys"
          label="Liidestuse võtmed"
        >
          <SimpleFormIterator inline>
            <TextInput source="key" helperText={false} />
          </SimpleFormIterator>
        </ArrayInput>
      </Item>
    </Grid>
  )
}

const SportProfileIconSelect = (props: { label?: string, source: string }) => {

  const { label, source } = props;

  const sportProfileKeys = keys(profileIconMap);

  const ProfileSelect = () => {
    const record = useRecordContext();
    return (
      <Stack direction="row" gap={1}>
        <SportProfileIcon sportsKey={record.id as SportIconKey} />
        {record.id}
      </Stack>
    )
  }

  return (
    <SelectInput
      source={source}
      fullWidth
      required
      label={label}
      defaultValue="human"
      choices={(sportProfileKeys ?? []).map(k => {
        return {
          id: k,
          name: k
        }
      })}
      optionText={
        <ProfileSelect />
      }
    />
  )
}

export const profileIconMap = {
  "basketball": mdiBasketball,
  "badminton": mdiBadminton,
  "boxingGlove": mdiBoxingGlove,
  "bike": mdiBike,
  "skate": mdiSkate,
  "handball": mdiHandball,
  "yoga": mdiYoga,
  "kabaddi": mdiKabaddi, 
  "kayaking": mdiKayaking,
  "rowing": mdiRowing,
  "run": mdiRun,
  "ski-cross-country": mdiSkiCrossCountry,
  "soccer": mdiSoccer,
  "swim": mdiSwim,
  "tennis": mdiTennis,
  "volleyball": mdiVolleyball,
  "rollerblade": mdiRollerblade,
  "dumbell": mdiDumbbell,
  "meditation": mdiMeditation,
  "weight-lifter": mdiWeightLifter,
  "human": mdiHuman
}

type SportIconKey = keyof typeof profileIconMap;

export const SportProfileIcon = (props: { sportsKey: SportIconKey }) => {
  const { sportsKey } = props;
  return <Icon size={1} path={profileIconMap[sportsKey] || profileIconMap["human"]} />
}