import { FileDownload } from "@mui/icons-material";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, FormGroup } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { Button, downloadCSV, ExportButtonProps as ExportButtonPropsBase, useListContext } from "react-admin";
import { Parser } from "json2csv";
import { get, isEmpty, reduce } from "lodash";
import { graphClient } from "../../server/apolloClient";
import { WORKOUT_EXPORT_QUERY } from "../../api/queries/exportData";

export interface ExportButtonProps extends ExportButtonPropsBase {
  dataKey?: string;
  exportFields?: string[],
  nestedValueMap?: {
    // root key and required path of the value
    [key: string]: string;
  };
}

export const ExportButton = (props: ExportButtonProps) => {

  const { data, resource, filterValues } = useListContext();
  const { nestedValueMap, dataKey, exportFields } = props;

  const [open, setOpen] = useState(false);
  const [unselected, setUnselected] = useState<string[]>([]);

  const fields = useMemo(() => exportFields || (isEmpty(data) ? [] : Object.keys(data[0])), [data, exportFields]);

  const CheckBoxGroup = useCallback(() => {
    return (
      <>
        <FormGroup>
          {fields.map((f: string, i: number) => {
            const isSelected = !unselected.includes(f);
            return (
              <FormControlLabel
                key={`select_${i}`}
                control={
                  <Checkbox
                    name={f}
                    checked={isSelected}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                      return checked
                        ? setUnselected(unselected.filter(u => u !== f))
                        : setUnselected([...unselected, f])
                    }} />
                }
                label={f}
              />
            )
          })}
        </FormGroup>
      </>
    )
  }, [fields, unselected])

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fetchExportData = useCallback(async () => {
    if (!dataKey) {
      return data
    }

    const from = get(filterValues, 'greaterOrEquals?start')
    const to = get(filterValues, 'lessOrEquals?start')
    const userIds = get(filterValues, 'in?userId');
    const groups = get(filterValues, 'in?groups');
    const sportProfileIds = get(filterValues, 'in?sportProfile.id');

    const result = await graphClient
      .query({
        query: WORKOUT_EXPORT_QUERY,
        variables: {
          from,
          to,
          userIds,
          groups,
          sportProfileIds,
        }
      })
      
    return result.data?.[dataKey]
  }, [filterValues])

  const handleOnClick = useCallback(async () => {
    const exportData = await fetchExportData()

    const parser = new Parser({
      fields: fields.filter((f: any) => !unselected.includes(f))
    });

    const csv = parser.parse(exportData.map((d: any) => {
      return reduce(d, (result, value, key) => {
        if (key === '__typename') return result;
        const mappedPath = get(nestedValueMap ?? {}, key);
        if (!!mappedPath) {
          value = get(d, `${key}.${mappedPath}`);
        }
        return { ...result, ...{ [key]: value } };
      }, {});
    }));

    downloadCSV(csv, resource);
    setOpen(false);
  }, [data, resource, fields, unselected, nestedValueMap, filterValues]);

  return <>
    <Button label="Export" startIcon={<FileDownload />} onClick={handleOpen}></Button>
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Selected fields
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <CheckBoxGroup />
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} label="Close" />
        <Button label="Export" onClick={handleOnClick} />
      </DialogActions>
    </Dialog>
  </>
}