import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, Grid } from "@mui/material";

interface FilterContainerProps {
  children: JSX.Element | JSX.Element[];
}

export const FilterContainer = (props: FilterContainerProps) => {
  const { children } = props;

  return (
    <Card>
      <CardHeader title="Kitsendused" sx={{ padding: 1.5 }} titleTypographyProps={{ fontSize: 16 }} />
      <Divider />
      <CardContent sx={{ padding: 1.5 }}>
        <Grid container columnSpacing={2}>
          {children}
        </Grid>
      </CardContent>
      <Divider />
      <CardActions sx={{ padding: 1.5 }}>
        <Grid container item xs={12} display="flex" justifyContent="flex-end" gap={2}>
          <Button variant="contained" color="primary" type="submit" size="small">
            Filtreeri
          </Button>
        </Grid>
      </CardActions>
    </Card>
  )
}