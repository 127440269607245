import { GetOneParams } from "react-admin";
import { WORKOUT_ONE } from "../api/queries/workout";
import { graphClient } from "../server/apolloClient";
import { map, omit } from "lodash";
import { USER_ONE } from "../api/queries/user";
import { SPORT_PROFILE_ONE } from "../api/queries/sportProfile";
import { SUBJECTIVE_DATA_ONE } from "../api/queries/subjectiveData";
import { USER_GROUPS_ONE } from "../api/queries/userGroups";

export const getOne = (resource: string, params: GetOneParams) => {

  return graphClient
    .query({
      query: getOneQuery(resource).gql,
      variables: {
        id: params.id,
      },
    })
    .then((result: any) => findOneData(result, resource));
}

const getOneQuery = (resource: string) => {

  switch (resource) {
    case "sportProfiles": return {
      gql: SPORT_PROFILE_ONE,
    };
    case "users": return {
      gql: USER_ONE,
    };
    case "userGroups": return {
      gql: USER_GROUPS_ONE,
    };
    case "workouts": return {
      gql: WORKOUT_ONE,
    };
    case "subjectiveDatas": return {
      gql: SUBJECTIVE_DATA_ONE,
    };
    default: throw new Error(`Unknown resource '${resource}' for find one query`);
  }
}

const findOneData = (result: any, resource: string) => {
  const resultname = resource.slice(0, -1) + "FindById";

  const baseData = omit(result.data[resultname], "__typename");

  if (resource === "sportProfiles") {
    return {
      data: {
        ...baseData,
        externalKeys: map(baseData?.externalKeys, (key) => ({ key }))
      }
    }
  }

  return {
    data: baseData
  };
}
