import { Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import { DateTimeInput, DateInput, NumberInput, TimeInput } from "react-admin";
import { getGreaterOrEqualsSource, getLessOrEqualsSource, getNumberGreaterOrEqualsSource, getNumberLessOrEqualsSource } from "../../dataProvider/filterLogic";
import { FormInputContainer } from "../atoms/formInputContainer";

interface RangeInputProps {
  label?: string;
  source: string;
  type: "date" | "number" | "time";
}

export const RangeInput = (props: RangeInputProps) => {

  const { label, source: src, type, ...rest } = props;

  const getInput = useCallback((t: "from" | "to") => {

    const getGreaterOrEquals = () => type === "number" ? getNumberGreaterOrEqualsSource(src) : getGreaterOrEqualsSource(src);
    const getLessOrEquals = () => type === "number" ? getNumberLessOrEqualsSource(src) : getLessOrEqualsSource(src);

    const source = t === "from" ? getGreaterOrEquals() : getLessOrEquals();
    const spacingFixes = {
      hiddenLabel: true,
      label: "",
    }

    switch (type) {
      case "date": return (
        <DateInput {...rest} source={source} sx={{ width: 164 }} {...spacingFixes} inputProps={{ width: 224 }}
          helperText={false} />
      );
      case "number": return (
        <NumberInput {...rest} source={source} sx={{ width: 100 }} {...spacingFixes} helperText={false} />
      );
      case "time": return (
        <TimeInput {...rest} source={source} sx={{ width: 100 }} inputProps={{ step: 2 }} {...spacingFixes} 
          helperText={false} />
      );
      default:
        throw new Error(`Unknown range type '${type}'`)
    }
  }, [src, type, rest]);

  const FromInput = useMemo(() => getInput("from"), [getInput]);

  const ToInput = useMemo(() => getInput("to"), [getInput]);

  return (
    <FormInputContainer label={label}>
      {FromInput}
      <Typography px={.5}>—</Typography>
      {ToInput}
    </FormInputContainer>
  )
}