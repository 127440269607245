import { useMemo } from "react";
import { BooleanInput as BooleanInputBase, BooleanInputProps } from 'react-admin';
import { getBooleanEqauls } from "../../dataProvider/filterLogic";
import { FormInputContainer } from "../atoms/formInputContainer";

export const BooleanInput = (booleanInputProps: BooleanInputProps) => {

  const { label, source: src, ...props } = booleanInputProps;

  const customProps = useMemo(() => {
    return {
      label: `${label ?? src}`,
      source: getBooleanEqauls(src),
    }
  }, [label, src]);

  return (
    <FormInputContainer label={customProps.label}>
      <BooleanInputBase {...props} {...customProps} helperText={false} label="" />
    </FormInputContainer>
  );
}

BooleanInput.defaultProps = {
  options: {},
};